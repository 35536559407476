@import "../../styles/variables/colors.scss";
@import "../../styles/variables/variables.scss";

.tabs {
    &__labels {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 0;

        li {
            min-width: 7rem;
            text-align: center;
            &:not(.tabs__labels--selected) {
                box-shadow: inset 0px -2px 0px $color-grey-bold;
            }
        }

        &__btn {
            padding: 1rem;
            font-size: 1rem;
            font-weight: normal;
            width: 100%;
            color: $color-sky;
            border: none;
            background: none;
            cursor: pointer;

            &:hover {
                background-color: $color-sky-transparent;
            }

            &:active {
                background-color: $color-sky-active;
            }
        }
        
        &--selected {
            color: $color-sky;
            box-shadow: inset 0px -3px 0px $color-sky-bright;
        }
    }
    &__content {
       border-top: 0.125rem solid $color-grey-extra-light;
        padding: $section-padding;
    }
}
@import "../../styles/variables/colors.scss";

.profile-dropdown {  
  .dropdown-menu {
    background: $color-white;
    border-radius: 0.5rem;
    position: absolute;
    right: 0.25rem;
    width: 15rem;
    margin-top: 1rem;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    box-shadow: 2px 2px 5px $color-grey-light;
    transform: translateY(0);
    cursor: pointer;

    &__btn {
      background: none;
      border: 1px solid $color-grey-light;
      border-radius: 50%;
      padding: 0.5em 0.6em;
      cursor: pointer;

      &:hover,
      &:active {
        box-shadow: 0 0 0.25rem $color-sky-transparent;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      a,
      .button {
        box-sizing: border-box;
        border-left: 3px solid transparent;
        border-right: none;
        border-top: none;
        border-bottom: none;
        background: $color-white;
        border-radius: 0;
        color: $color-black;
        width: 100%;
        box-shadow: none;
        display: flex;
        justify-content: stretch;
        align-items: center;
        font-size: 1rem;
        font-weight: 600;
        padding: 0.75rem;
        transition: 150ms ease all;
        text-decoration: none;

        &:hover,
        &:focus {
          background-color: $color-cloud-active;
          border-color: $color-sky;
          color: $color-sky;
          transform: none;
        }
      }

      // Logout button
      .button {
        color: $color-red;

        &:hover, &:focus {
          background-color: $color-red-soft;
          color: $color-red;
          border-color: $color-red;
        }
      }
    }

    &__icon {
      margin-right: 0.75rem;
    }

    &--active {
      opacity: 1;
      visibility: visible;
    }
  }

  &--active .dropdown-menu__btn {
    box-shadow: 0 0 0.25rem $color-sky-transparent;
  }
}
@import "../../styles/mixins/container.scss";
@import "../../styles/variables/colors.scss";
@import "../../styles/variables/breakpoints.scss";
@import "../../styles/variables/z-index.scss";

.authpage {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  flex-direction: column;

  &__nav {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z-index-top;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $section-padding * 3 $section-padding;
    z-index: $z-index-middle;
    height: 100%;

    > .card {
      width: 25rem;
    }
  }

  &__logo-wrapper {
    @include logo-wrapper;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      max-width: 11rem;
    }
  }

  @media #{$breakpoint-mobile} {
    justify-content: flex-start;
    
    &__nav {
      position: static;
    }

    &__content > .card {
      width: auto;
    }
  }
}

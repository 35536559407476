@import "../../../styles/variables/colors.scss";
@import "../../../styles//mixins/forms.scss";

.dropdown {
    padding-top: 4px;
    font-size: 0.875rem;
    line-height: 1rem;

    &--with-errors {
        .dropdown__control {
            border-color: $color-red !important;
        }
    }
    
    &__label,
    &__menu {
        @include form-label;
    }

    &__value-container {
        padding: 0.5rem !important;
        text-transform: capitalize;
    }

    &__indicator-separator {
        display: none;
    }

    &__single-value {
        color: $color-black !important;
        font-size: 0.875rem;
        line-height: 1rem;
    }

    &__menu {
        padding: 0.5rem 0;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    &__control {
        margin: 0.25rem 0 1.5rem;
        padding: 0;
        max-height: 40px; // alignment fix
    }
    
    &__required {
        @include required-asterisk;
    }
}


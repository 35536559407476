@import "../variables/colors.scss";

@mixin form-label {
    color: $color-black;
    font-size: 0.875rem;
    line-height: 1rem;
    text-transform: capitalize;
    font-weight: normal;
}

@mixin form-input {
    border: 1px solid $color-grey-extra-light;
    box-sizing: border-box;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    line-height: 1rem;
    width: 100%;
    padding: 0.6875rem 0.75rem;
    margin: 0.25rem 0 1.5rem;

    &--with-errors {
        border-color: $color-red;
    }
}

@mixin required-asterisk {
    color: $color-red;
    font-weight: bold;
    margin-left: 0.125rem;
}

@mixin truck-animation($step, $time: 0.5s) {
    -webkit-animation: truck-animation-#{$step} $time forwards;
    animation: truck-animation-#{$step} $time forwards;
}

@mixin keyframe-range($name, $start, $end) {
    @keyframes #{$name} {
        0%   {
            left: $start;
        }
        100%  {
            left: $end;
        }
    }
}
    
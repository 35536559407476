@import "../../styles/variables/breakpoints.scss";

.card-row {
  display: flex;

  &--column {
    flex-direction: column;
  }

  & > * {
    flex: 1;
    overflow: auto;
  }

  &__spacer {
    flex: none;
    width: 2rem;
  }

  @media #{$breakpoint-tablet} {
    flex-direction: column;

    &__spacer {
      display: none;
    }
  }
}

@mixin h1 {
  font-size: 1.75rem;
}

@mixin h2 {
  font-size: 1.5rem;
}

@mixin h3 {
  font-size: 1.25rem;
}

@mixin h4 {
  font-size: 1.15rem;
}

@mixin h5 {
  font-size: 1rem;
}

@mixin h6 {
  font-size: 1rem;
}

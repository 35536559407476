@import "../../../styles/variables/colors.scss";
@import "../../../styles//mixins/forms.scss";

.date-field {
  padding-top: 4px;
  font-size: 0.875rem;
  line-height: 1rem;
  
  &__label{
    @include form-label;
  }

  &__input {
    @include form-input;
    &--with-errors {
      border-color: $color-red;
    }
  }
}

.react-datepicker-popper {
  z-index: 5;
}


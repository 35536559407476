@import "../../../styles/variables/colors.scss";
@import "../../../styles/mixins/forms.scss";

.text-field {
    &__label {
        @include form-label;
    }

    &__input {
        @include form-input;

        &--error {
            border-color: $color-red;
        }
    }
    
    &__helper-text {
        margin-top: -1rem;
        margin-bottom: 1.5rem;
        display: flex;
        align-items: center;
        font-style: italic;
        font-size: 0.8125rem;

        > svg {
            margin-right: 0.25rem;
        }
    }

    &__required {
        @include required-asterisk;
    }
}
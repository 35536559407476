@import "../../styles/variables/colors.scss";
@import "../../styles/mixins/forms.scss";
@import "../../styles/variables/breakpoints.scss";
@import "../../styles/variables/z-index.scss";

#multi-step-form--success {
    height: 8rem;
}

.multi-step-form {
    @media #{$breakpoint-non-mobile} {
        &__group {
            display: flex;
            justify-content: space-between;

            &  > div {
                flex-grow: 1;
                flex-basis: 0;

                &:not(:last-child) {
                    padding-right: 0.5rem;
                }
            }
        }
    }

    @media #{$breakpoint-tablet} {
        &__group__radio {
            display: block;
        }
    }

    &__form-content {
        position: relative;
    }

    &__loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.75);
        z-index: $z-index-top;
    }
    
    &__animation {
        height: 3rem;
        position: relative;
        display: inline-block;
        transform: translatex(-50%);

        &--wrapper {
            padding-left: 2.5%;
            padding-right: 2.5%;
            overflow: hidden;
            margin-bottom: -0.75rem;
        }

        &--move-1 {
            @include truck-animation(1);
        }

        &--move-2 {
            @include truck-animation(2);
        }

        &--move-3 {
            @include truck-animation(3, 1s);
            animation-delay: 0.5s;
        }

        &--move-4 {
            @include truck-animation(4);
        }

        &--hide {
            visibility: hidden;
        }
    }

    &__indicator{
        position: relative;
        margin-bottom: 2.5rem;
        padding-left: 2.5%;
        padding-right: 2.5%;

        &__icon-wrapper {
            text-align: center;
            z-index: 5;
            position: absolute;
            transform: translatex(-50%);

            &:nth-child(1) {
                left: 0;
            }

            &:nth-child(2) {
                left: 50%;
            }

            &:nth-child(3) {
                left: 100%;
            }
        }

        &__wrapper {
            position: relative;
            display: block;
            width: 100%;
            min-height: 50px;
        }

        &__line {
            position: absolute;
            top: 27%;
            width: 95%;
            left: 2.5%;
            border: 1px solid $color-sky;
        }

        &__label {
            font-size: 0.875rem;
            display: block;
        }

        &__icon {
            color: $color-sky;
            fill: $color-white;
            stroke-width: 1px;
    
            &--filled {
                fill: $color-sky;
                stroke-width: 1px;
            }
        }
    }

    &__success-content {
        text-align: center;
        button {
            margin: auto;
        }
    }

    &__summary {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__filter {
        display: flex;
        justify-content: space-between;
        
        &__label {
            @include form-label;
        }

        &__group {
            width: calc(33.33% - 0.67rem);

            .filter__control {
                margin: 0.25rem 0 0.5rem 0;
            }
        }
    }

    &__summary-field {
        text-transform: capitalize;
        flex: 1;
    }

    &__summary-title {
        font-size: 1.25rem;
        margin-top: 2rem;
        
        & + .multi-step-form__filter + .multi-step-form__group {
            .radio {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                
                &__option__label {
                    display: flex;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100%;
                }

                &__label {
                    display: none;
                }

                &-group {
                    width: calc(33.33% - 0.67rem);
                }

                @media #{$breakpoint-tablet} {
                    display: flex;

                    &-group {
                        flex: 0 0 auto;
                        width: calc(50% - 0.5rem);
                    }
                }

                @media #{$breakpoint-mobile} {
                    &-group {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@include keyframe-range('truck-animation-1', 0%, 50%);
@include keyframe-range('truck-animation-2', 50%, 100%);
@include keyframe-range('truck-animation-3', 100%, 150%);
@include keyframe-range('truck-animation-4', 150%, 150%);
@import "../../styles/variables/breakpoints.scss";

@mixin modal-width {
    width: 25rem;

    @media #{$breakpoint-mobile} {
        max-height: 100vh;
        overflow: scroll;
    }
}

@mixin modal-overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $z-index-modal;
}
@import "../../styles/variables/colors.scss";
@import "../../styles/variables/z-index.scss";
@import "../../styles/mixins/modal.scss";

.payment-modal {
  @include modal-width;

  &-overlay {
    @include modal-overlay;
  }

  &__animation--wrapper {
    text-align: center;
  }

  &__success-text {
    margin-top: -1rem;
    text-align: center;
  }

  #payment-modal__animation-icon:not(:empty) {
    height: 5rem;
    margin-top: -1rem;
  }

  .cost-row {
    display: flex;
    justify-content: space-between;
  }

  .underline {
    width: 100%;
    border-bottom: 1px dashed $color-black;
    margin: 0.5rem 0;
  }
}

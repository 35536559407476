@import '../../styles/mixins/container.scss';
@import '../../styles/variables/colors.scss';
@import '../../styles/variables/breakpoints.scss';
@import '../../styles/variables/variables.scss';
@import '../../styles/variables/z-index.scss';

$loader-fade-speed: 1s;

$max-content-width: 1680px;

.app {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;

  &__body {
    background-color: $color-cloud;
    padding-left: $sidenav-width;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__wrapper {
    padding: $section-padding;
    flex: 1;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0.5rem;

      &-track {
        background-color: transparent;
      }

      &-thumb {
        background-color: $color-grey-light;
        border-radius: 2rem;

        &:hover {
          background-color: $color-grey;
        }
      }
    }
  }

  &__content {
    max-width: $max-content-width;
    margin: auto;
  }

  &__loader {
    background: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: $z-index-loader;

    &-wrapper {
      @include logo-wrapper;
      
      > img {
        opacity: 0;
      }
    }

    &--animating {
      animation: fade ease $loader-fade-speed forwards;

      .app__loader-wrapper > img {
        animation: logo-fade-in ease $loader-fade-speed forwards;
      }
    }
  }

  @media #{$breakpoint-tablet} {
    &__body {
      padding-left: 0;
    }

    &__wrapper {
      padding: $section-padding-mobile calc(#{$section-padding-mobile} - 10px)
        4rem $section-padding-mobile;
    }
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes logo-fade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@import "../../styles/variables/colors.scss";

.pill {
  padding: 0.25rem 1rem;
  border-radius: 1.25rem;
  border: 1px solid transparent;
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1.25rem;
  overflow: hidden;
  text-transform: capitalize;

  p {
    margin: 0;
  }

  &--primary {
    background-color: $color-plum;
    color: $color-white;
  }

  &--secondary {
    background-color: $color-plum-background;
    color: $color-white;
  }

  &--tertiary {
    background-color: $color-grey-extra-light;
    color: $color-black;
  }

  &--alert {
    background-color: $color-yellow;
    color: $color-black;
  }

  &--success {
    background-color: $color-green;
    color: $color-white;
  }

  &--error {
    border-color: $color-grey-soft;
    background-color: $color-red-soft;
    color: $color-black;
  }
}

@import "../../styles/mixins/links.scss";
@import "../../styles/mixins/typography.scss";
@import "../../styles/variables/colors.scss";
@import "../../styles/variables/breakpoints.scss";
@import "../../styles/variables/variables.scss";
@import "../../styles/variables/z-index.scss";

.header {
  height: 6rem;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  box-shadow: 2px 2px 5px $color-grey-light;
  background-color: $color-white;
  z-index: $z-index-top;

  &__actions {
    display: flex;
    align-items: center;
  }

  &__breadcrumbs {
    display: flex;
  }

  &__crumb {
    @include h1;

    &--last {
      font-weight: 600;
    }

    &--link {
      display: flex;

      & > a {
        @include text-link-black;
      }
    }
  }

  &__crumb-spacer {
    margin: 0 1rem;
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__logo-wrapper {
    display: none;
    margin-right: 1rem;

    > img {
      max-height: 3rem;
    }
  }

  &__actions__buttons {
    display: flex;
    align-items: center;
  }

  .text-button .button {
    @include h5;
    color: $color-sky;
    display: flex;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    padding-right: 0;
    box-shadow: none;
    font-weight: normal;

    &:hover, &:focus {
      color: $color-sky-active;
      text-decoration: underline;
      transform: none;
    }
  }

  @media #{$breakpoint-tablet} {
    height: 4rem;
    padding: $section-padding-mobile;

    &__tablet-hidden {
      display: none;
    }

    &__logo-wrapper {
      display: block;
    }
  }

  @media #{$breakpoint-mobile} {
    height: 3rem;
    padding: 0.25rem;

    &__breadcrumbs {
      display: none;
    }

    &__content {
      justify-content: flex-end;
    }
  }
}

@import "./mixins/links";

$margin-levels: 1, 2, 3, 4;

.text-link {
  @include text-link;
}

.form-errors {
  margin-top: -1rem;
  margin-bottom: 1.5rem;
  color: $color-red;
  font-size: 0.875rem;

  &::first-letter {
    text-transform: uppercase;
  }
}

@each $margin in $margin-levels {
  .mt-#{$margin} {
    margin-top: #{$margin * 0.5}rem;
  }

  .mb-#{$margin} {
    margin-bottom: #{$margin * 0.5}rem;
  }

  .ml-#{$margin} {
    margin-left: #{$margin * 0.5}rem;
  }

  .mr-#{$margin} {
    margin-right: #{$margin * 0.5}rem;
  }
}

.button-group {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.625rem;
  margin-top: 2rem;

  button:last-of-type {
    margin-left: 1.25rem;
  }

  svg {
    color: $color-white;
  }
}

.d-flex {
  display: flex;
}

.no-wrap {
  white-space: nowrap;
}
@import "../../styles/variables/colors.scss";

.button {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  transition: all 250ms ease;
  position: relative;
  box-shadow: 0 1px 3px $color-grey-light;
  color: $color-white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &.button--primary {
      background-color: $color-sky-active;
      border-color: $color-sky-active;
    }

    &.button--secondary {
      color: $color-white;
      background-color: $color-sky;
      border-color: $color-sky;
    }

    &.button--dark {
      background-color: $color-white;
      border-color: $color-white;
      color: $color-black;
    }
  }

  &--primary {
    background-color: $color-sky;
    border: 0.125rem solid $color-sky;

    .loader > svg {
      color: $color-white;
      cursor: not-allowed;
      height: 1.25rem;
      width: 1.25rem;
    }

    &:hover,
    &:focus {
      background-color: $color-sky-active;
      border-color: $color-sky-active;
    }
  }

  &--secondary {
    background-color: $color-white;
    border: 0.125rem solid $color-sky;
    color: $color-sky;

    &:hover, 
    &:focus {
      background-color: $color-sky;
      border-color: $color-sky;
      color: $color-white;
    }
  }

  &--dark {
    background-color: $color-black;
    border: 0.125rem solid $color-black;

    &:hover,
    &:focus {
      background-color: $color-white;
      border-color: $color-white;
      color: $color-black;
    }
  }

  &--cancel {
    background-color: $color-red;
    border: 0.125rem solid $color-red;
  }

  &--margin {
    margin-top: 1rem;
  }

  &--smaller {
    padding: 0.25rem 1rem;
    font-size: 0.75rem;
  }

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0px);
  }

  // Animated circles
  &--primary {
    &:before, &:after{
      position: absolute;
      content: '';
      display: block;
      width: 140%;
      height: 100%;
      left: -20%;
      z-index: -1000;
      transition: all 250ms ease;
      background-repeat: no-repeat;
    }
    
    &:before{
      display: none;
      top: -75%;
      background-image:  
        radial-gradient(circle, $color-sky 20%, transparent 20%),
        radial-gradient(circle,  transparent 20%, $color-sky 20%, transparent 30%),
        radial-gradient(circle, $color-sky 20%, transparent 20%), 
        radial-gradient(circle, $color-sky 20%, transparent 20%),
        radial-gradient(circle, transparent 10%, $color-sky 15%, transparent 20%),
        radial-gradient(circle, $color-sky 20%, transparent 20%),
        radial-gradient(circle, $color-sky 20%, transparent 20%),
        radial-gradient(circle, $color-sky 20%, transparent 20%),
        radial-gradient(circle, $color-sky 20%, transparent 20%);
      background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
    }
    
    &:after{
      display: none;
      bottom: -75%;
      background-image:  
        radial-gradient(circle, $color-sky 20%, transparent 20%), 
        radial-gradient(circle, $color-sky 20%, transparent 20%),
        radial-gradient(circle, transparent 10%, $color-sky 15%, transparent 20%),
        radial-gradient(circle, $color-sky 20%, transparent 20%),
        radial-gradient(circle, $color-sky 20%, transparent 20%),
        radial-gradient(circle, $color-sky 20%, transparent 20%),
        radial-gradient(circle, $color-sky 20%, transparent 20%);
      background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
    }
  }

  &--animate {
    &:before{
      display: block;
      animation: topBubbles ease 0.75s forwards;
    }
    &:after{
      display: block;
      animation: bottomBubbles ease 0.75s forwards;
    }
  }
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
    opacity: 1;
  }

  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }

  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    opacity: 0;
  }
}

@keyframes bottomBubbles {
  0%{
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
    opacity: 1;
  }
  
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
  }
 
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    opacity: 0;
  }
}

@import "../../styles/mixins/container.scss";
@import "../../styles/variables/colors.scss";
@import "../../styles/variables/breakpoints.scss";
@import "../../styles/variables/variables.scss";
@import "../../styles/variables/z-index.scss";

.navbar {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 2px 2px 5px $color-grey-light;
  background-color: $color-white;
  width: $sidenav-width;
  height: 100%;
  z-index: $z-index-nav;

  &__logo-wrapper {
    @include logo-wrapper;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
  }

  &__link {
    > a {
      border-left: 3px solid transparent;
      color: $color-black;
      display: flex;
      justify-content: stretch;
      font-weight: 600;
      padding: 0.75rem;
      transition: 150ms ease all;
      text-decoration: none;

      &:hover,
      &:focus {
        background-color: $color-cloud-active;
        border-color: $color-sky;
        color: $color-sky;
      }
    }

    &--active > a {
      background-color: $color-cloud-active;
      border-color: $color-sky;
      color: $color-sky;
    }
  }

  &__link-icon {
    margin-right: 0.75rem;
  }

  @media #{$breakpoint-tablet} {
    top: auto;
    position: fixed;
    bottom: 0;
    height: auto;
    width: 100%;

    &__content {
      display: flex;
      width: 100%;
      margin: 0;
    }

    &__logo-wrapper {
      display: none;
    }

    &__link {
      width: 33.33%;

      > a {
        border-left: none;
        border-top: 1px solid $color-grey;
        flex-direction: column;
        align-items: center;
      }
    }

    &__link-icon {
      margin: 0 0 0.125rem;
    }
  }
}

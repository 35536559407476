@import "../../styles/variables/colors.scss";
@import "../../styles/variables/breakpoints.scss";

.big-stat {
  text-align: center;
  display: inline-block;

  &__label {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  &__value {
      font-size: 3.75rem;
      line-height: 5rem;
      font-weight: bold;
      color: $color-sky;
      margin-bottom: 0.25rem;
  }

  @media #{$breakpoint-mobile} {
    width: 50%;
    margin: 0.5rem 0;

    &__label {
      font-size: 0.875rem;
      line-height: 1rem;
    }

    &__value {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
}

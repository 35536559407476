// Reserved for components
$z-index-modal: 9001;
$z-index-loader: 9000;
$z-index-nav: 1000;
$z-index-header: 800;

// Relative positioning
$z-index-top: 30;
$z-index-middle: 20;
$z-index-bottom: 10;

@import "../../../styles/variables/colors.scss";
@import "../../../styles//mixins/forms.scss";

.checkbox {
    margin: 0.25rem 0 1.5rem;
    
    &__label {
        font-size: 0.875rem;
        line-height: 1rem;
        color: $color-black;

        input {
            margin-right: 0.25rem;
        }
    }

}
@import "../../styles/variables/colors.scss";
@import "../../styles/variables/z-index.scss";
@import "../../styles/mixins/modal.scss";

.proceed-without-validation-modal {
  @include modal-width;

  &-overlay {
    @include modal-overlay;
  }
}

@import "../../styles/variables/colors.scss";
@import "../../styles/variables/breakpoints.scss";
@import "../../styles/variables/variables.scss";

.material-table {
  &__toolbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    .MuiToolbar-root {
      padding: 0;
  
      @media #{$breakpoint-mobile} {
        display: none;
      }
    }
  }

  .MuiPaper-root {
    box-shadow: none;
  }
  .MuiInput {
    &-underline {
      &:hover:not(.Mui-disabled):before,
      &:before,
      &:after {
        border-bottom: none;
      }
    }

    &-input {
      padding: 0;
    }
  }

  .MuiFormControl-root {
    padding-left:0;
    border: 2px solid $color-grey-extra-light !important;
    border-radius: 0.125rem;
    width: 22rem;
  }

  .MuiInputBase-root {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    flex-direction: row-reverse;

    svg {
      color: $color-sky;
    }
  }

  .MuiInputAdornment-positionEnd {
    display: none;
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      color: $color-black;
      border-bottom: 1px solid $color-grey-light;
    }
    tr:nth-child(even) {
      background-color: $color-white;
    }
      
    tr:nth-child(odd) {
      background-color: $color-off-white;
    }

    tr:last-child {
      .MuiTableRow-root {
        border-bottom: none;
      }
    }
  }

  .MuiTableRow-head{
    border-bottom: 1px solid $color-grey-light;
  }
  
  .MuiTableCell-root {
    font-size: 0.75rem !important;
    line-height: 1.125rem;
    color: $color-grey-soft;
    font-weight: normal;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
}
@import "../../styles/variables/colors.scss";
@import "../../styles/variables/breakpoints.scss";
@import "../../styles/variables/variables.scss";
@import "../../styles/mixins/typography.scss";
@import "../../styles/mixins/box-shadows.scss";

.card {
  @include box-shadow($color-sky-transparent);
  background-color: $color-white;
  border-radius: 1rem;
  padding: $section-padding;
  box-shadow: 0 0 0.25rem $color-sky-transparent;
  margin-bottom: $section-padding;

  &--flex > .card__body {
    display: flex;
    justify-content: space-around;

    
    @media #{$breakpoint-mobile} {
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }

  &__title {
    @include h2;
    margin-bottom: $section-padding;
    margin-bottom: $section-padding-mobile;
    display: flex;
    justify-content: space-between;
  }

  &__btn {
    @include h5;
    color: $color-sky;
    display: flex;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    padding-right: 0;

    svg {
      margin-left: 0.5rem;
    }
    
    &:hover, &:focus {
      color: $color-sky-active;
      text-decoration: underline;
    }
  }

  &__tab {
    padding: 0;
    
    .card__title {
      padding: $section-padding $section-padding 0 $section-padding;
    }
  }
  
  @media #{$breakpoint-tablet} {
    &:not(.card__tab) {
      padding: $section-padding-mobile;
      margin-bottom: $section-padding-mobile;  
    }
  }

}

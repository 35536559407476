@import "../../styles/variables/colors.scss";

.banner {
	padding: 1rem;
	display: flex;
	align-items: center;
	border-radius: 0.25rem;
	margin-bottom: 1.5rem;
	font-weight: 500;

	> svg {
		margin-right: 0.25rem;
		flex: 0 0 auto;
	}

	&--success {
		background: $color-green-soft;
		color: $color-green;
	}

	&--error {
		background: $color-red-soft;
		color: $color-red;
	}

	&--info {
		background: $color-sky-soft;
		color: $color-sky;
	}

	&--alert {
		background: $color-yellow-soft;
		color: $color-gold;
	}
}

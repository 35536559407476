@import "../../../styles/variables/colors.scss";
@import "../../../styles//mixins/forms.scss";
@import "../../../styles/variables/breakpoints.scss";

.radio {
    margin-bottom: 0.75rem;

    &-group {
        display: inline-block;
    }
    
    &__option__label {
        font-size: 1rem;
        line-height: 1.125rem;
        color: $color-black;
        margin: 0.625rem 0 0.5rem 0;
        border: 1px solid $color-grey-extra-light;
        box-shadow: 0 0 0.25rem $color-grey-light;
        padding: 1rem;
        border-radius: 0.625rem;
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        svg {
            margin-right: 0.5rem;
        }

        p {
            margin: 0;
        }

        &--selected {
            background-color: $color-sky-background;
            border-color: $color-sky-active;

            .pill {
                background-color: $color-sky;
                color: $color-white;
            }
        }
        
        .pill {
            border-radius: 0.75rem;
            margin-right: 0.75rem;
            padding: 1rem;
            font-size: 1rem;
            text-align: center;
            min-width: 2.25rem;
        }

        @media #{$breakpoint-tablet} {
            padding: 0.5rem;
            white-space: nowrap;
        }
    }

    &__label {
        @include form-label;
    }

    input{
       margin-right: 0.5rem;
       opacity: 0;
       position: fixed;
       width: 0;
    }

    &__option {
        &__image {
            display: inline;
            margin-right: 3rem;
            max-height: 3rem;
        }

        &__svg-label {
            display: flex;
            font-size: 1rem;

            @media #{$breakpoint-only-small-desktop} {        
                flex-direction: column;
                align-items: flex-start;
            }

            img {
               height: 1rem;
               width: auto;
               margin-right: 0.5rem;
               margin-bottom: 0.25rem;
            }

            & + p {
                margin: 0;
                font-size: 0.875rem;
            }
        }

        &__text-label {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
@import "../variables/breakpoints.scss";
@import "../variables/variables.scss";

@mixin container {
  width: 100%;
  max-width: $bp-desktop + 0px;
  margin: auto;
}

@mixin logo-wrapper {
  height: 6rem;
  padding: 0 $section-padding;

  & > img {
    max-height: 100%;
  }
}

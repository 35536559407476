$color-sky: #3c8de5;
$color-sky-soft:  #3c8de520;
$color-sky-active: #5399e2;
$color-sky-transparent: #3c8de54d;
$color-sky-background: #E0EAFF;
$color-sky-bright: #074EE8;

$color-plum: #3f3ce5;
$color-plum-active: #5653e2;
$color-plum-background: #BAB9FF;

$color-cloud: #f3f7ff;
$color-cloud-active: #e0eaff;

$color-red: #FF6F5C;
$color-red-soft: #FF6F5C0f;
$color-green: #35bf2b;
$color-green-soft: #35bf2b0f;
$color-yellow: #FFE75F;
$color-yellow-soft: #FFFBE2;
$color-gold: #DBBA00;

$color-black: #0f0f0f;
$color-grey: #a8bcbe;
$color-grey-soft: #7E7E7E;
$color-grey-bold: #AAAAAA;
$color-grey-light: #e5e5e5;
$color-grey-extra-light: #CCCCCC;
$color-white: #fff;
$color-off-white: #FAFAFA;

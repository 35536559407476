@import "../../styles/variables/colors.scss";

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    color: $color-sky;
    animation: rotate linear 0.75s infinite;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-180deg);
  }
}
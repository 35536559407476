@import "../variables/colors.scss";

$link-animation: 250ms ease;

@mixin text-link {
  color: $color-sky;
  text-decoration: none;
  border-bottom: 1px solid $color-sky;
  transition: color $link-animation, border-color $link-animation;

  &:hover,
  &:focus {
    border-color: $color-sky-active;
    color: $color-sky-active;
  }
}

@mixin text-link-black {
  @include text-link;
  border-color: $color-black;
  color: $color-black;
}
@import "../../styles/variables/colors.scss";

.credit-summary-row {
	display: flex;
	justify-content: space-between;
	font-size: 1.125rem;
	line-height: 1.375rem;
	font-weight: bold;
	margin-bottom: 1rem;
	
	&__text {
		color: $color-black;
	}

	&__value {
		color: $color-sky;
	}
}